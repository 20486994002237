<template>
    <div id="cookbook-root">
        <div id="cookbook-root-wrapper">
            <RecipeAdd @savedRecipe="showAddedRecipe" ref="recipeAdd" />
            <div id="cookbook-header">
                <Header />
            </div>
            <div id="cookbook-body">
                <div v-if="loaded" id="cb-outer-wrapper" class="outer-wrapper">
                    <div class="cb-title-wrapper p-col-12 p-d-print-none">
                        <span class="cb-title noUserSelect page-title"
                            >My Recipe Book</span
                        >
                        <Button
                            id="addRecipebtn"
                            icon="pi pi-plus-circle"
                            class="p-button-outlined"
                            label="Add New Recipe"
                            @click="onAddRecipeBtnClick"
                        />

                    </div>
                    <div
                        v-if="recipeList && recipeList.length > 0"
                        id="cb-wrapper"
                    >
                        <transition name="fastFade" mode="out-in">
                            <div v-if="!cbViewBook" id="cb-tile-view">
                                <DataView
                                    :value="recipeList"
                                    layout="grid"
                                    :paginator="false"
                                    :sortOrder="tileSortOrder"
                                    :sortField="tileSortField"
                                >
                                    <template #header>
                                        <div
                                            id="tile-header"
                                            class="p-grid p-nogutter"
                                        >
                                            <div
                                                class="p-col-6"
                                                style="text-align: left"
                                            >
                                                <span
                                                    v-if="
                                                        recipeList &&
                                                            recipeList.length >
                                                                0
                                                    "
                                                    >{{
                                                        recipeList.length
                                                    }}
                                                    Saved Recipes</span
                                                >
                                            </div>
                                            <div
                                                class="p-col-6"
                                                style="text-align: right"
                                            >
                                                <Dropdown
                                                    v-model="tileSortKey"
                                                    :options="tileSortOptions"
                                                    optionLabel="label"
                                                    placeholder="Most Recent"
                                                    @change="
                                                        onTileSortChange($event)
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </template>
                                    <template #grid="recipe">
                                        <transition
                                            name="fastFade"
                                            mode="out-in"
                                        >
                                            <div
                                                class="cb-tile-card-wrapper p-col-12 p-md-6 p-lg-3"
                                            >
                                                <RecipeCard
                                                    :recipe="recipe.data"
                                                    :title="recipe.data.title"
                                                    :image="recipe.data.image"
                                                    :calories="
                                                        recipe.data.calories
                                                    "
                                                    :carbs="
                                                        recipe.data
                                                            .carbohydrates
                                                    "
                                                    :cooktime="
                                                        recipe.data.cooktime
                                                    "
                                                    :url="recipe.data.source"
                                                    :createdOn="
                                                        recipe.data.addDate
                                                    "
                                                    :showRemove="true"
                                                    @onClick="
                                                        onRecipeClick(
                                                            recipe.data,
                                                            undex
                                                        )
                                                    "
                                                />
                                            </div>
                                        </transition>
                                    </template>
                                </DataView>
                            </div>
                            <div v-else id="cb-book-view">
                                <div id="cb-book-columns-normal" class="p-grid">
                                    <div
                                        id="cb-book-sidebar-web"
                                        class="p-col-4 p-d-none p-d-md-block"
                                    >
                                        <RecipeList
                                            :recipes="recipeList"
                                            @onRecipeClick="
                                                onRecipeSelectedFromList
                                            "
                                            :defaultSelected="defaultSelected"
                                        />
                                    </div>
                                    <div
                                        id="cb-book-sidebar-mobile"
                                        class="p-d-block p-d-md-none p-d-print-none p-col-1"
                                    >
                                        <span
                                            class="cb-book-sidebar-menu-icon"
                                            @click="onMenuClick"
                                        >
                                            <i class="pi pi-bars" />
                                        </span>
                                        <Sidebar
                                            :visible="showMobileRecipeList"
                                            position="left"
                                            @show="onShowRecipeListMobile"
                                            @hide="onHideRecipeListMobile"
                                            :dismissable="true"
                                            :showCloseIcon="true"
                                            :autoZIndex="true"
                                        >
                                            <div
                                                class="cb-book-mobile-recipe-list-wrapper"
                                            >
                                                <RecipeList
                                                    :recipes="recipeList"
                                                    @onRecipeClick="
                                                        onRecipeSelectedFromListMobile
                                                    "
                                                    :defaultSelected="
                                                        defaultSelected
                                                    "
                                                />
                                            </div>
                                        </Sidebar>
                                    </div>
                                    <div
                                        id="cb-book-viewer"
                                        class="p-col-11 p-md-8"
                                    >
                                        <transition
                                            name="fastFade"
                                            mode="out-in"
                                        >
                                            <div
                                                class="cb-book-details-wrapper"
                                                v-if="currentRecipeData"
                                            >
                                                <RecipeDetails
                                                    v-if="
                                                        currentRecipeData.status &&
                                                            currentRecipeItem
                                                    "
                                                    :data="currentRecipeData"
                                                    :visible="true"
                                                    :noAds="true"
                                                    :removable="true"
                                                    :smallVersion="true"
                                                    :createdOn="
                                                        currentRecipeItem.addDate
                                                    "
                                                />
                                                <div
                                                    v-else-if="
                                                        currentRecipeData.source
                                                    "
                                                    class="cb-book-link-only-wrapper"
                                                >
                                                    <div
                                                        class="cb-book-link-view"
                                                    >
                                                        <span
                                                            class="cb-book-link-text"
                                                            >To View this
                                                            recipe, please
                                                            follow this link
                                                        </span>
                                                        <span
                                                            class="cb-book-link-cta"
                                                        >
                                                            <a
                                                                class="cb-book-goto-link"
                                                                target="_blank"
                                                                :href="
                                                                    currentRecipeData.source
                                                                "
                                                                >Click Here</a
                                                            >
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-else-if="loadingRecipe"
                                                class="cb-book-details-wrapper"
                                            >
                                                loading...
                                            </div>
                                        </transition>
                                    </div>
                                </div>
                                <recommendation-carousel
                                    v-if="currentRecipeData"
                                    :key="currentRecipeData"
                                    :seedRecipes="[currentRecipeData]"
                                    :subheader="
                                        `Delicious recommendations based on ${currentRecipeData.title}`
                                    "
                                ></recommendation-carousel>
                            </div>
                        </transition>
                    </div>
                    <div v-else id="cb-norecipes-wrapper">
                        <h1>Recipe Book is empty</h1>
                        <p>
                            Start saving recipes by trying one of the following
                            options:
                        </p>
                        <ul>
                            <li>Browse recipes on the homepage</li>
                            <li>Search recipe keywords in the header above</li>
                            <li>Click the "Add New Recipe" button</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="cookbook-footer">
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "primevue/button";
import DataView from "primevue/dataview";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Footer from "../../components/footer/Footer.vue";
import Header from "../../components/header/Header";
import RecipeCard from "../../components/recipeCards/RecipeCard";
import RecipeDetails from "../../components/recipeDetails/RecipeDetails";
import RecipeList from "../../components/recipeList/RecipeList";
import RecipeAdd from "../../components/recipeAdd/RecipeAdd";
import RecommendationCarousel from "../../components/recommendationCarousel/RecommendationCarousel";
import userMixin from "../../store/mixins/user";
import { getRecipesFromApi } from "../../fetchFunctions";

export default {
    mixins: [userMixin],
    props: ["uuid"],
    components: {
        Header,
        Footer,
        Button,
        DataView,
        Dropdown,
        Sidebar,
        RecipeCard,
        RecipeDetails,
        RecipeList,
        RecipeAdd,
        RecommendationCarousel,
    },
    data() {
        return {
            recipeList: null,
            cbViewBook: false, // false for tile view, true for book list view
            loaded: true,
            tileSortField: "addDate",
            tileSortOrder: -1,
            tileSortOptions: [
                { label: "Most Recent", value: "dateNew" },
                { label: "Oldest", value: "dateOld" },
                { label: "Highest Calories", value: "caloriesHi" },
                { label: "Lowest Calories", value: "caloriesLo" },
                { label: "Highest Carbs", value: "carbohydratesHi" },
                { label: "Lowest Carbs", value: "carbohydratesLo" },
            ],
            tileSortKey: null,
            currentRecipeItem: null,
            currentRecipeData: null,
            loadingRecipe: false,
            defaultSelected: null,
            showMobileRecipeList: false,
            currentIndex: 0,
        };
    },
    created() {
        if (!this.activeUser) {
            return this.$router.push({
                name: "Home",
            });
        }
        if (this.uuid) {
            this.cbViewBook = true;
        }
    },
    async mounted() {
        if (
            this.loggedIn &&
            this.activeUser &&
            this.activeUser.recipeList &&
            Object.keys(this.activeUser.recipeList).length > 0
        ) {
            this.recipeList = this.formattedRecipeList(
                this.activeUser.recipeList
            );
            // Sort list to most recent first
            this.recipeList.sort((a, b) => b.addDate - a.addDate);

            this.defaultSelected = this.recipeList[0];

            if (this.uuid) {
                const res = await this.loadRecipeFromUUID(this.uuid);
                if (res) {
                    if (this.activeUser.recipeList[this.uuid])
                        this.currentRecipeItem = this.activeUser.recipeList[
                            this.uuid
                        ];
                    this.cbViewBook = true;
                    const recipeIndex = this.recipeList.findIndex(
                        x => x.uuid === this.uuid
                    );
                    this.defaultSelected = this.recipeList[recipeIndex];
                    this.currentIndex = recipeIndex;
                } else this.cbViewBook = false;
                return;
            }
        }

        this.loaded = true;
        this.loadGourmet();
    },
    watch: {
        loggedIn() {
            if (!this.activeUser) {
                return this.$router.push({
                    name: "Home",
                });
            }
        },
        "$store.state.user.user.recipeList": {
            handler() {
                this.updateRecipeList();
            },
            deep: true,
        },
        cbViewBook() {
            if (
                this.cbViewBook &&
                !this.currentRecipeData &&
                this.recipeList &&
                this.recipeList.length > 0
            ) {
                this.onRecipeClick(this.recipeList[0]);
            }
        },
    },
    methods: {
        onTileSortChange(event) {
            const { value } = event.value;
            const sortValue = event.value;

            switch (value) {
                case "dateNew":
                    this.tileSortOrder = -1;
                    this.tileSortField = "addDate";
                    break;
                case "dateOld":
                    this.tileSortOrder = 1;
                    this.tileSortField = "addDate";
                    break;
                case "caloriesHi":
                    this.tileSortOrder = -1;
                    this.tileSortField = "calories";
                    break;
                case "caloriesLo":
                    this.tileSortOrder = 1;
                    this.tileSortField = "calories";
                    break;
                case "carbohydratesHi":
                    this.tileSortOrder = -1;
                    this.tileSortField = "carbohydrates";
                    break;
                case "carbohydratesLo":
                    this.tileSortOrder = 1;
                    this.tileSortField = "carbohydrates";
                    break;
            }

            this.tileSortKey = sortValue;
        },
        formattedRecipeList(recipeObj) {
            if (recipeObj && Object.keys(recipeObj).length > 0) {
                const out = [];
                for (const i in recipeObj) {
                    const newRecipe = recipeObj[i];
                    newRecipe.uuid = i;
                    out.push(newRecipe);
                }
                return out;
            }
            return false;
        },
        async onRecipeClick(recipe) {
            this.currentRecipeData = null;
            this.currentRecipeItem = recipe;
            if (recipe && recipe.uuid && recipe.dataStatus) {
                const res = await this.loadRecipeFromUUID(recipe.uuid);
                if (res) {
                    this.cbViewBook = true;
                    this.defaultSelected = recipe;
                }
            } else if (recipe && !recipe.dataStatus) {
                // Link recipe, just load data
                this.currentRecipeData = recipe;
                this.cbViewBook = true;
                this.defaultSelected = recipe;
            }
            this.currentIndex = this.recipeList.findIndex(
                x => x.uuid === recipe.uuid
            );
        },
        async onRecipeSelectedFromList(recipe, index) {
            this.currentIndex = index;
            this.currentRecipeData = null;
            if (recipe && recipe.uuid && recipe.dataStatus) {
                await this.loadRecipeFromUUID(recipe.uuid);
            } else if (recipe && !recipe.dataStatus) {
                // Link recipe, just load data
                this.currentRecipeData = recipe;
            }
        },
        async onRecipeSelectedFromListMobile(recipe, index) {
            this.currentRecipeData = null;
            this.currentIndex = index;
            if (recipe && recipe.uuid && recipe.dataStatus) {
                await this.loadRecipeFromUUID(recipe.uuid);
            } else if (recipe && !recipe.dataStatus) {
                // Link recipe, just load data
                this.currentRecipeData = recipe;
            }
            this.showMobileRecipeList = false;
        },
        async loadRecipeFromUUID(uuid) {
            this.loadingRecipe = true;
            const res = await getRecipesFromApi(uuid, "uuid");
            if (
                res &&
                res.status &&
                res.results &&
                res.results[uuid] &&
                res.results[uuid].status &&
                res.results[uuid].data
            ) {
                res.results[uuid].data.uuid = uuid;
                this.currentRecipeData = res.results[uuid].data;
                return true;
            }
        },
        onMenuClick() {
            this.showMobileRecipeList = true;
        },
        onShowRecipeListMobile() {
            const closeBtn = document.querySelector(".p-sidebar-close");
            if (closeBtn)
                closeBtn.onclick = () => {
                    this.showMobileRecipeList = false;
                };
        },
        onHideRecipeListMobile() {
            this.showMobileRecipeList = false;
        },
        updateRecipeList() {
            if (!this.activeUser) return;
            const removed = this.activeUser.recipeList < this.recipeList;
            this.recipeList = this.formattedRecipeList(
                this.activeUser.recipeList
            );
            if (removed) {
                const newIndex = Math.min(
                    this.currentIndex,
                    this.recipeList.length - 1
                );
                this.defaultSelected = this.recipeList[newIndex];
                this.onRecipeSelectedFromList(
                    this.recipeList[newIndex],
                    newIndex
                );
            }
        },
        onAddRecipeBtnClick() {
            this.$refs.recipeAdd.show();
        },
        showAddedRecipe(recipe) {
            this.cbViewBook = true;
            const recipeIndex = this.recipeList.findIndex(
                x => x.uuid === recipe.uuid
            );
            this.defaultSelected = this.recipeList[recipeIndex];
            this.onRecipeSelectedFromList(
                this.recipeList[recipeIndex],
                recipeIndex
            );
        },
    },
};
</script>

<style scoped src="./recipebook.css"></style>
