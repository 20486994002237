<template>
    <div class="placeholder-card recipeCardRoot">
        <div
            class="recipe-card-wrapper p-grid p-shadow-1 recipe-card-no-cursor"
            @click="null"
        >
            <div class="recipe-card-left">
                <img src="/assets/images/freshy_recipes_icon.svg" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "recipe-card-placeholder",
    mixins: [],
    props: [],
    components: {},
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {},
};
</script>

<style scoped src="./recipeCard.css">
::v-deep(.recipe-card-no-cursor) {
    cursor: none;
}
</style>
