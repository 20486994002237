<template>
    <div class="lightbox p-shadow-3" v-if="showFirstRun">
        <div id="firstrunbox" class="box" :class="getPlatformClass">
            <div id="uparrow"></div>
            <div id="load-two">
                <div id="load-two-container">
                    <div id="load-two-top-container">
                        <Button
                            class="p-button-outlined p-component p-button-icon-only p-button-rounded p-button-secondary p-button-sm f-right m-5"
                            icon="pi pi-times"
                            @click="onCloseClick"
                        />
                        <div id="load-two-top">
                            <div id="load-two-tl">
                                <img
                                    src="/assets/images/freshysearch-ico.png"
                                />
                            </div>
                            <div id="load-two-tr">
                                <h2>Freshy Recipes</h2>
                                <p>
                                    Click <strong>"Keep It"</strong> to find and
                                    save all your favorite recipes.
                                </p>

                                <Button
                                    id="firstrunbutton"
                                    label="Start a Recipe Search"
                                    @click="onCloseClick"
                                />
                            </div>
                        </div>
                        <img id="ibg" src="/assets/images/instructionbg.jpg" />
                    </div>
                </div>
            </div>
            <!-- end loadtwo -->
        </div>
    </div>
</template>
<script>
import Button from "primevue/button";
import { getPlatformClass } from "../../utility";

export default {
    name: "first-run",
    components: {
        Button,
    },
    data() {
        return {
            getPlatformClass,
            showFirstRun: true,
        };
    },
    mounted() {},
    methods: {
        onCloseClick() {
            this.showFirstRun = false;
        },
    },
};
</script>

<style scoped v-if="" src="./firstrun.css"></style>
