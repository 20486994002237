<template>
    <div class="recipe-details-root" ref="recipeDetailsRoot">
        <!-- PAGE SECTION large- WILL NOT PRINT -->
        <div
            id="lg-view"
            v-if="data && !isSmallVersion"
            class="recipe-details-wrapper p-d-block p-d-print-none"
        >
            <div class="recipe-details-top">
                <div class="recipe-details-info">
                    <div class="rdinfo-wrapper p-grid">
                        <!--recipe IMG -->
                        <div class="recipe-hero-img p-md-5 p-col-12">
                            <img
                                @click="onImageClick()"
                                class="smart-recipe-details-img"
                                :src="
                                    data.finalimage_urls
                                        ? data.finalimage_urls[0]
                                        : ''
                                "
                                :alt="
                                    data.final_image_prompt ||
                                        'Cooking Freshy Recipe Main Image'
                                "
                            />
                        </div>

                        <div class="recipe-hero p-md-7 p-col-12">
                            <div class="recipe-details-title">
                                <h1>{{ data.title }}</h1>

                                <div class="recipe-details-btn-wrapper">
                                    <!-- save btn -->
                                    <div
                                        class="recipe-details-save-btn-wrapper"
                                    >
                                        <recipe-save-btn
                                            class="recipe-details-save-btn"
                                            :recipe="customData"
                                            :bypassLogin="true"
                                        ></recipe-save-btn>
                                    </div>

                                    <!-- print btn -->
                                    <div
                                        class="p-shadow-2 printBtn btn"
                                        @click="onPrintClick"
                                    >
                                        <i class="pi pi-print"></i>
                                        <span>Print Recipe</span>
                                    </div>

                                    <!-- nutri btn -->
                                    <div
                                        class="nutritionBtn-wrapper"
                                        v-if="
                                            nutritionMetadata &&
                                                nutritionMetadata.length > 0
                                        "
                                    >
                                        <span
                                            class="p-shadow-2 nutritionBtn btn"
                                            @click="toggleNutrientWindow"
                                            aria:haspopup="true"
                                            aria-controls="overlay_panel"
                                        >
                                            <i class="pi pi-list"></i>
                                            <span>Nutrition Data</span>
                                        </span>
                                        <Overlay-Panel
                                            id="nutrition-overlay-panel"
                                            ref="nutrientWindow"
                                            style="width: 450px"
                                            :breakpoints="{ '960px': '75vw' }"
                                        >
                                            <div
                                                v-if="
                                                    nutritionMetadata &&
                                                        nutritionMetadata.length >
                                                            0
                                                "
                                                id="recipe-nutrition-overlay"
                                                class="recipe-details-meta p-grid"
                                            >
                                                <div
                                                    class="recipe-details-meta-label p-col-12"
                                                >
                                                    Nutrition Data
                                                    <span
                                                        class="nutritional-note p-col-12"
                                                        >Nutrition values are
                                                        estimated based on title
                                                        and/or ingredients</span
                                                    >
                                                </div>
                                                <div
                                                    v-for="(i,
                                                    index) in nutritionMetadata"
                                                    :key="index"
                                                    class="recipe-details-meta-item-wrapper p-col-12 p-md-6"
                                                >
                                                    <div
                                                        class="recipe-details-meta-item"
                                                    >
                                                        <span
                                                            class="recipe-details-meta-item-label"
                                                            >{{ i.label }}</span
                                                        >
                                                        <span
                                                            class="recipe-details-meta-item-value"
                                                            >{{ i.value }}</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </Overlay-Panel>
                                    </div>
                                </div>
                                <!-- end btn wrapper -->

                                <!-- hero meta -->
                                <div class="recipe-hero-meta">
                                    <div class="rdm-left">
                                        <div
                                            v-if="data.total_time"
                                            class="detail-meta-item"
                                        >
                                            <div class="detail-meta-item-label">
                                                Total Time
                                            </div>
                                            <div class="detail-meta-item-data">
                                                {{ data.total_time }}
                                            </div>
                                        </div>
                                        <div
                                            v-if="data.complexity"
                                            class="detail-meta-item"
                                        >
                                            <div class="detail-meta-item-label">
                                                Complexity
                                            </div>
                                            <div class="detail-meta-item-data">
                                                {{ data.complexity }}
                                            </div>
                                        </div>

                                        <div
                                            class="detail-meta-item"
                                            v-if="data.ai_score"
                                        >
                                            <div class="detail-meta-item-label">
                                                Rating
                                            </div>
                                            <div
                                                id="rating-stars"
                                                class="detail-meta-item-data"
                                            >
                                                <div id="empty-star">
                                                    <i class="pi pi-star"></i>
                                                    <i class="pi pi-star"></i>
                                                    <i class="pi pi-star"></i>
                                                    <i class="pi pi-star"></i>
                                                    <i class="pi pi-star"></i>
                                                </div>

                                                <div
                                                    id="fill-star"
                                                    class="fillpercent"
                                                    :style="{
                                                        width:
                                                            data.ai_score + '%',
                                                    }"
                                                >
                                                    <i class="pi pi-star"></i>
                                                    <i class="pi pi-star"></i>
                                                    <i class="pi pi-star"></i>
                                                    <i class="pi pi-star"></i>
                                                    <i class="pi pi-star"></i>
                                                </div>
                                                {{ data.rating }}
                                            </div>
                                        </div>

                                        <!-- <div class="detail-meta-item">
                                  <div v-if="data.rating" class="detail-meta-item-label">Rating</div>
                                      <div class="detail-meta-item-data">
                                        {{ data.rating }}
                                  </div>
                                  
                              </div> -->
                                    </div>
                                </div>
                                <!-- end hero meta -->
                            </div>
                        </div>
                    </div>
                    <!-- rdwrapper -->
                  <div  class="recipe-description-wrapper p-grid p-col-12">
                   <div v-for="(desc,index) in data.description"  :key="index">{{ desc }}</div>
                  </div>


                    <div class="recipe-instructions-meta p-grid">
                        <div id="ingredients-block" class="p-col-12 p-md-5">
                            <div class="rd-side-detail">
                                <div
                                    v-if="this.ingredients"
                                    class="recipe-details-meta-label"
                                >
                                    <span> <h2>Ingredients</h2>
                                        <div
                                            v-if="regularData.yields"
                                            class="serving-size"
                                        >
                                            <div class="serving-total">
                                                {{ regularData.yields }}
                                            </div>
                                        </div> </span>

                                    <div class="ingredient-btn ui-bar">
                                        

                                        <div class="measure r-flex-end">
                                            <div
                                                :class="[
                                                    !isActive ? 'active' : '',
                                                    'measure-btn',
                                                    'ui-bar-btn',
                                                ]"
                                                @click="convertAlltoUS"
                                            >
                                                US
                                            </div>
                                            <div
                                                :class="[
                                                    isActive ? 'active' : '',
                                                    'measure-btn',
                                                    'ui-bar-btn',
                                                ]"
                                                @click="convertAll"
                                            >
                                                Metric
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ingredient-list-container">
                                    <div
                                        class="ingred-item"
                                        v-for="(item, index) in this
                                            .ingredients"
                                        :key="index"
                                    >
                                        <div class="ingred-amount">
                                            {{
                                                item.convertedQuantity ||
                                                    item.quantity +
                                                        " " +
                                                        item.unit
                                            }}
                                        </div>
                                        <div class="ingred-name">
                                            {{ item.ingredient }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="rd-side-detail">
                                <div
                                    class="recipe-details-meta-label"
                                    v-if="
                                        data.courses ||
                                            data.type_of_dish ||
                                            data.ethnicity ||
                                            data.regions
                                    "
                                >
                                    <h2>{{ data.title }} Details</h2>
                                </div>

                                <div class="recipe-details-meta-item-container">
                                    <div
                                        v-if="data.courses"
                                        class="recipe-details-meta-item"
                                    >
                                        <span
                                            class="recipe-details-meta-item-label"
                                            >Courses</span
                                        ><span
                                            class="recipe-details-meta-item-value"
                                            >{{
                                                data.courses &&
                                                data.courses.length > 1
                                                    ? data.courses.join(", ")
                                                    : data.courses[0]
                                            }}</span
                                        >
                                    </div>

                                    <div
                                        v-if="data.type_of_dish"
                                        class="recipe-details-meta-item"
                                    >
                                        <span
                                            class="recipe-details-meta-item-label"
                                            >Type of Dish</span
                                        ><span
                                            class="recipe-details-meta-item-value"
                                            >{{
                                                data.type_of_dish &&
                                                data.type_of_dish.length > 1
                                                    ? data.type_of_dish.join(
                                                          ", "
                                                      )
                                                    : data.type_of_dish[0]
                                            }}</span
                                        >
                                    </div>

                                    <div
                                        v-if="data.ethnicity"
                                        class="recipe-details-meta-item"
                                    >
                                        <span
                                            class="recipe-details-meta-item-label"
                                            >Ethnicity</span
                                        ><span
                                            class="recipe-details-meta-item-value"
                                            >{{
                                                data.ethnicity &&
                                                data.ethnicity.length > 1
                                                    ? data.ethnicity.join(", ")
                                                    : data.ethnicity[0]
                                            }}</span
                                        >
                                    </div>

                                    <div
                                        v-if="data.regions"
                                        class="recipe-details-meta-item"
                                    >
                                        <span
                                            class="recipe-details-meta-item-label"
                                            >Region</span
                                        ><span
                                            class="recipe-details-meta-item-value"
                                            >{{
                                                data.regions &&
                                                data.regions.length > 1
                                                    ? data.regions.join(", ")
                                                    : data.regions[0]
                                            }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="instructions-block" class="p-col-12 p-md-7">
                            <div class="instruction-list-container">
                                <div class="recipe-details-meta-label">
                                    <h2>Instructions</h2>

                                    <div
                                        v-if="data.instructions_images"
                                        class="instruction-btn ui-bar"
                                    >
                                        <div class="r-flex-end">
                                            <i
                                                class="pi pi-image"
                                                data-v-63d43a28=""
                                            ></i>
                                            <span
                                                @click="
                                                    toggleIngredientsImages()
                                                "
                                                >{{ toggleImages }}</span
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="instruct-item"
                                    v-for="(item, index) in data.instructions"
                                    :key="index"
                                >
                                    <div class="instruct-title-img">
                                        <div class="instruct-item-title">
                                            <div
                                                class="instruct-item-title-wrapper"
                                            >
                                                <span
                                                    class="instruct-item-label"
                                                    >Step {{ item.step }}
                                                </span>

                                                <span>{{ item.title }}</span>
                                                <div class="instruct-prep-time">
                                                    {{ item.preptime }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-show="!hideIngredientsImages"
                                            v-if="data.instructions_images"
                                            class="instruct-item-img"
                                        >
                                            <img
                                                :src="
                                                    data.instructions_images
                                                        ? data
                                                              .instructions_images[
                                                              index
                                                          ].image_urls[0]
                                                        : ''
                                                "
                                                :alt="
                                                    data.instructions
                                                        ? data.instructions[
                                                              index
                                                          ].image_prompt
                                                        : ''
                                                "
                                            />
                                        </div>
                                    </div>

                                    <div class="instruct-item-copy">
                                        <div class="instruct-wrapper">
                                            {{ item.description }}
                                        </div>
                                        <div class="instruct-helpful">
                                            <strong>Helpful Tip</strong>
                                            {{ item.helpful_tips }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="nutritionMetadata && nutritionMetadata.length > 0"
                        id="recipe-nutrition"
                        class="recipe-details-meta p-grid p-d-md-none"
                    >
                        <div class="recipe-details-meta-label p-col-12">
                            Nutrition Data
                            <span class="nutritional-note p-col-12"
                                >Nutrition values are estimated based on title
                                and/or ingredients</span
                            >
                        </div>
                        <div
                            v-for="(i, index) in nutritionMetadata"
                            :key="index"
                            class="recipe-details-meta-item-wrapper p-col-12"
                        >
                            <div class="recipe-details-meta-item">
                                <span class="recipe-details-meta-item-label">{{
                                    i.label
                                }}</span>
                                <span class="recipe-details-meta-item-value">{{
                                    i.value
                                }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="recipe-details-sidebar p-col-12">
                    <div v-if="data.history" class="recipe-details-meta-item">
                        <span class="recipe-details-meta-item-label"
                            ><h3>{{ data.title }} History</h3></span
                        ><span class="recipe-details-meta-item-value">{{
                            data.history && data.history.length > 1
                                ? data.history.join("\n")
                                : data.history[0]
                        }}</span>
                    </div>
                </div>
            </div>
            <div class="recipe-details-bottom"></div>
            <Dialog
                v-if="data && data.image"
                class="rd-large-image-dialog"
                :modal="true"
                :dismissableMask="true"
                :showHeader="false"
                contentClass="large-image-dialog-wrapper"
                :visible="showLargeImage"
                @update:visible="hideLargeImage"
            >
                <img class="rd-large-image" :src="data.image" />
            </Dialog>
        </div>
        <!-- END PAGE SECTION 1- WILL NOT PRINT -->

        <!-- PRINT ONLY SECTION - WILL PRINT -->
        <div
            v-if="data"
            id="printRecipe"
            class="recipe-details-print p-d-none p-d-print-block"
        >
            <div class="p-grid p-col-12">
                <div class="p-col-12">
                    <img
                        id="printLogo"
                        class="p-d-none p-d-print-block"
                        src="/assets/images/freshy_recipes_logo.svg"
                        alt="Cooking Freshy Recipes"
                    />

                    <div class="print-page-title">
                        {{ data.title }}
                    </div>
                </div>

                <div id="print-ingredients" class="p-col-4">
                    <div
                        v-if="this.ingredients"
                        class="print-page print-col-title"
                    >
                        <h2>Ingredients</h2>
                    </div>
                    <div class="print-ingredient-list">
                        <div
                            class="print-ingredient-item"
                            v-for="(item, index) in this.ingredients"
                            :key="index"
                        >
                            <span class="print-ingredient-amount">{{
                                item.convertedQuantity ||
                                    item.quantity + " " + item.unit
                            }}</span>
                            <span class="print-ingredient-name">{{
                                item.ingredient
                            }}</span>
                        </div>
                    </div>

                    <div class="print-recipe-details-meta-item-container">
                        <div
                            v-if="data.courses"
                            class="print-recipe-details-meta-item"
                        >
                            <div class=" print-col-title">Courses</div>
                            <span
                                class="print-recipe-details-meta-item-value"
                                >{{
                                    data.courses && data.courses.length > 1
                                        ? data.courses.join(", ")
                                        : data.courses[0]
                                }}</span
                            >
                        </div>

                        <div
                            v-if="data.type_of_dish"
                            class="print-recipe-details-meta-item"
                        >
                            <div class=" print-col-title">Type of Dish</div>
                            <span
                                class="print-recipe-details-meta-item-value"
                                >{{
                                    data.type_of_dish &&
                                    data.type_of_dish.length > 1
                                        ? data.type_of_dish.join(", ")
                                        : data.type_of_dish[0]
                                }}</span
                            >
                        </div>

                        <div
                            v-if="data.ethnicity"
                            class="print-recipe-details-meta-item"
                        >
                            <div class=" print-col-title">Ethnicity</div>
                            <span
                                class="print-recipe-details-meta-item-value"
                                >{{
                                    data.ethnicity && data.ethnicity.length > 1
                                        ? data.ethnicity.join(", ")
                                        : data.ethnicity[0]
                                }}</span
                            >
                        </div>

                        <div
                            v-if="data.regions"
                            class="print-recipe-details-meta-item"
                        >
                            <div class=" print-col-title">Region</div>
                            <span
                                class="print-recipe-details-meta-item-value"
                                >{{
                                    data.regions && data.regions.length > 1
                                        ? data.regions.join(", ")
                                        : data.regions[0]
                                }}</span
                            >
                        </div>
                        <div
                            v-if="data.history"
                            class="print-recipe-details-meta-item"
                        >
                            <div class=" print-col-title">History</div>
                            <span
                                class="print-recipe-details-meta-item-value"
                                >{{
                                    data.history && data.history.length > 1
                                        ? data.history.join("\n")
                                        : data.history[0]
                                }}</span
                            >
                        </div>
                    </div>
                </div>
                <div id="print-instructions" class="p-col-8">
                    <div class="print-page print-col-title">
                        <h2>Instructions</h2>
                    </div>

                    <div class="print-instruction-list">
                        <div
                            class="print-instruction-item"
                            v-for="(item, index) in data.instructions"
                            :key="index"
                        >
                            <div class="print-instruction-item-title">
                                <div
                                    class="print-instruction-item-title-wrapper"
                                >
                                    <span class="print-instruction-item-label"
                                        >Step {{ item.step }}
                                    </span>

                                    <span>{{ item.title }}</span>
                                    <div class="print-instruction-prep-time">
                                        {{ item.preptime }}
                                    </div>
                                </div>
                            </div>
                            <!-- 
                      <div  v-if="data.instructions_images" class="instruct-item-img">
                        <img :src="data.instructions_images ? data.instructions_images[index].image_urls[0] : ''" alt="image name"/>
                      </div>
                      -->

                  <div class="print-instruction-item-copy">
                                <div class="print-instruction-wrapper">
                                    {{ item.description }}
                                </div>

                                <div class="print-instruction-helpful">
                                    <strong>Helpful Tip</strong>
                                    {{ item.helpful_tips }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end col8 instructions -->
            </div>
        </div>
        <!-- END PRINT ONLY SECTION - WILL PRINT -->
    </div>
</template>

<script>
import recipeMixin from "../componentMixins/recipeMixin";
import OverlayPanel from "primevue/overlaypanel";
import Dialog from "primevue/dialog";
import RecipeSaveBtn from "../recipeSaveBtn/RecipeSaveBtn";
import { getShortMonthName, getDateSuffix } from "../../utility";

export default {
    name: "recipe-details-overlay",
    mixins: [recipeMixin],
    props: [
        "data",
        "visible",
        "noAds",
        "removable",
        "smallVersion",
        "createdOn",
        "regularData",
    ],
    components: {
        OverlayPanel,
        RecipeSaveBtn,
        Dialog,
    },
    data() {
        return {
            show: false,
            showLargeImage: false,
            hideIngredientsImages: false,
            toggleImages: "Hide Images",
            ingredients: this.data.ingredients,
            customData: {},
            isActive: false,
        };
    },
    mounted() {
        this.show = this.visible;
        window.scrollTo(0, 0);

        this.$nextTick(() => {
            const ga = document.querySelector("#ga_sticky_bottom");
            if (ga) ga.classList.add("p-d-print-none");
        });
        this.customData = this.data;
        this.customData.url = `cooking.freshy.com/recipe/${this.data.uuid}`;
        // populating structred data of page

        const recipe = {};

        // Adding static values
        recipe["@context"] = "https://schema.org/";
        recipe["@id"] = this.data.uuid;

        recipe["@type"] = "Recipe";

        recipe.author = {
            "@type": "Organization",
            name: "Freshy Cooking", // Value from function input
        };

        if (this.data && this.data.title) {
            recipe.name = this.data.title;
        }

        // Adding dynamic values
        if (this.data && this.data.tags) {
            const tags = JSON.stringify(this.data.tags);
            recipe.keywords = tags
                .substring(1, tags.length - 1)
                .replace(/"/g, " ");
        }
        if (this.data && this.data.finalimage_urls) {
            recipe.image = this.data.finalimage_urls;
        }

        if (this.regularData && this.regularData.yields) {
            recipe.recipeYield = this.regularData.yields;
        }

        if (
            this.data &&
            this.data.description &&
            this.data.description.length > 0
        ) {
            recipe.description = this.data.description;
        }

        if (this.data && this.data.ethnicity) {
            const ethnicity = JSON.stringify(this.data.ethnicity);
            recipe.recipeCuisine = ethnicity
                .substring(1, ethnicity.length - 1)
                .replace(/"/g, " ");
        }

        if (this.data && this.data.total_time) {
            recipe.totalTime = this.data.total_time;
        }

        if (this.data && this.data.type_of_dish) {
            const type_of_dish = JSON.stringify(this.data.type_of_dish);
            recipe.recipeCategory = type_of_dish
                .substring(1, type_of_dish.length - 1)
                .replace(/"/g, " ");
        }

        if (this.data.ai_score) {
            recipe.aggregateRating = {
                "@type": "AggregateRating",
                ratingValue:
                    this.data.ai_score > 70
                        ? 4
                        : this.data.ai_score > 85
                        ? 5
                        : this.data.ai_score > 35 && this.data.ai_score < 70
                        ? 3
                        : this.data.ai_score < 35 && this.data.ai_score > 15
                        ? 2
                        : 1,
                ratingCount: this.data.ai_score,
            };
        }
        // Add nutrition info dynamically
        if (this.data.nutrition && this.data.nutrition.total_calories) {
            recipe.nutrition = {
                "@type": "NutritionInformation",
                calories: `${this.data.nutrition.total_calories} calories`,
            };
        }

        // Adding more fields dynamically
        if (this.data.ingredients) {
            recipe.recipeIngredient = this.data.ingredients;
        }

        const recipeInstructions = [];

        // Define each step dynamically and push to the array

        if (this.data.instructions) {
            this.data.instructions.forEach(element => {
                recipeInstructions.push({
                    "@type": "HowToStep",
                    name: element.title,
                    text: element.description,
                });
            });
        }
        if (recipeInstructions) recipe.recipeInstructions = recipeInstructions;
        if (recipe) {
            const script = document.createElement("script");
            script.setAttribute("type", "application/ld+json");
            script.textContent = JSON.stringify(recipe);
            document.head.appendChild(script);
        }
    },
    watch: {
        visible() {
            this.show = this.visible;
        },
    },
    computed: {
        formattedCreatedOn() {
            if (!this.createdOn || isNaN(this.createdOn)) return null;

            const date = new Date(this.createdOn);
            const month = getShortMonthName(date.getMonth());
            const day = date.getDate();
            const year = date.getFullYear();

            if (month && day && year)
                return `${month} ${day}${getDateSuffix(day)}, ${year}`;
            return null;
        },
        canonicalHost() {
            try {
                if (this.data && this.data.canonical) {
                    const url = new URL(this.data.canonical);
                    if (url && url.host) return url.host;
                }
                return null;
            } catch {
                return null;
            }
        },
        isSmallVersion() {
            return this.smallVersion || null;
        },
        metadata() {
            const out = [];
            if (this.data) {
                const r = this.data;

                const addMeta = (label, value) => out.push({ label, value });

                // Order Matters

                // Adding Created Date First
                // if(this.formattedCreatedOn) addMeta("Added To Cookbook", this.formattedCreatedOn)

                if (r.total_time && this.formatTimeFromMinutes(r.total_time))
                    addMeta(
                        "Total Time",
                        this.formatTimeFromMinutes(r.total_time)
                    );
                if (r.yields) addMeta("Serving Size", r.yields);
                return out != [] ? out : null;
            }
            return null;
        },
        nutritionMetadata() {
            const out = [];
            const addMeta = (label, value, unit = null) =>
                out.push({ label, value, unit });

            let n = null;
            if (
                this.data &&
                this.data.nutrition &&
                Object.keys(this.data.nutrition).length > 0
            )
                n = this.data.nutrition;
            else if (
                this.data &&
                this.data.nutrient_details &&
                Object.keys(this.data.nutrient_details).length > 0
            )
                n = this.data.nutrient_details;

            for (const i in n) {
                addMeta(this.parseNutrientString(i), n[i]);
            }

            if (out && out.length > 0) return out;
            return null;
        },
    },
    methods: {
        onImageClick() {
            this.showLargeImage = true;
        },
        toggleIngredientsImages() {
            this.hideIngredientsImages = !this.hideIngredientsImages;
            if (this.toggleImages == "Hide Images")
                this.toggleImages = "Show Images";
            else this.toggleImages = "Hide Images";
        },
        hideLargeImage(value) {
            if (!value) this.showLargeImage = false;
        },
        onShow() {
            const closeBtn = document.querySelector(".p-sidebar-close");
            if (closeBtn)
                closeBtn.onclick = () => {
                    this.show = false;
                };
        },
        onHide() {},
        toggleNutrientWindow(event) {
            this.$refs.nutrientWindow.toggle(event);
        },
        onPrintClick() {
            if (window) {
                window.print();
            }
        },

        // Function to convert to metric
        convertToMetric(ingredient) {
            // Conversion factors
            const conversions = {
                pkg: 1, // Keep package as is
                lemon: 1, // Keep lemon zest as is
                oz: 28.3495, // 1 oz = 28.3495 grams
                ounces: 28.3495, // 1 oz = 28.3495 grams
                ounce: 28.3495, // 1 oz = 28.3495 grams
                lb: 0.453592, // 1 pound = 0.453592 kilograms
                pounds: 0.453592, // For plural
                "": 1, // Eggs, no conversion needed
            };

            if (ingredient.unit in conversions) {
                const conversionFactor = conversions[ingredient.unit];
                const convertedQuantity =
                    ingredient.quantity * conversionFactor;

                // Return quantity in the appropriate unit (ml, g, kg, etc.)
                if (ingredient.unit === "lb" || ingredient.unit === "pounds") {
                    return `${convertedQuantity.toFixed(2)} kg`;
                } if (
                    ingredient.unit === "oz" ||
                    ingredient.unit === "ounces" ||
                    ingredient.unit === "ounce"
                ) {
                    return `${convertedQuantity.toFixed(2)} g`;
                } 
                    return `${ingredient.quantity} ${ingredient.unit}`;
                
            } 
                return `${ingredient.quantity} ${ingredient.unit}`; // Return as is if no conversion available
            
        },
        convertAll() {
            this.isActive = !this.isActive;

            // Loop through all ingredients and apply conversion
            this.ingredients = this.data.ingredients.map(ingredient => {
                return {
                    ...ingredient,
                    convertedQuantity: this.convertToMetric(ingredient),
                };
            });
        },
        convertAlltoUS() {
            this.isActive = !this.isActive;
            // Loop through all ingredients and apply conversion
            this.ingredients = this.data.ingredients.map(ingredient => {
                return {
                    ...ingredient,
                    convertedQuantity: null,
                };
            });
        },
    },
};
</script>

<style src="./smartrecipeDetails.css"></style>
