<template>
    <div id="auth-window-root">
        <Toast position="top-center" group="tc" />
        <transition name="fade" mode="out-in">
            <div
                v-if="mode == 'signin'"
                id="auth-signin"
                class="authWindowWrapper"
            >
                <div class="auth-window-top">
                    <span v-if="reauthentication" class="authWindowTitle"
                        >Reauthenticate</span
                    >
                    <span v-else class="authWindowTitle"
                        >Enter your email address to log in or create an
                        account.</span
                    >
                </div>
                <div class="auth-window-middle">
                    <form id="auth-window-login">
                        <div
                            class="auth-window-email auth-window-middle-section"
                        >
                            <span class=" authInputWrapper">
                                <label for="useremail">Email</label>
                                <input-text
                                    id="useremail"
                                    class="authInput"
                                    v-model="userEmail"
                                    type="text"
                                    :class="{
                                        authInputInvalid:
                                            submitErrors.loginEmail,
                                    }"
                                    @keypress.enter="onLoginClick()"
                                />
                                <div class="authInputErrorMsg">
                                    <span v-if="submitErrors.loginEmail">{{
                                        submitErrors.loginEmail
                                    }}</span>
                                </div>
                            </span>
                            <span class="authInputWrapper">
                                <label for="password">Password</label>
                                <password
                                    id="password"
                                    inputClass="authInput"
                                    style="width: 100%;"
                                    v-model="userPass"
                                    :feedback="false"
                                    :class="{
                                        authInputInvalid:
                                            submitErrors.loginPassword,
                                    }"
                                    @keypress.enter="onLoginClick()"
                                >
                                    <template #header>
                                        <h4 class="authPasswordTooltipTitle">
                                            Pick a Password
                                        </h4>
                                    </template>
                                    <template #footer>
                                        <Divider />
                                        <p class="">Password Requirements</p>
                                        <ul class="p-pl-2 p-ml-2 p-mt-0">
                                            <li>At least one lowercase</li>
                                            <li>At least one uppercase</li>
                                            <li>At least one numeric</li>
                                            <li>Minimum 8 characters</li>
                                        </ul>
                                    </template>
                                </password>
                                <div class="authInputErrorMsg">
                                    <span v-if="submitErrors.loginPassword">{{
                                        submitErrors.loginPassword
                                    }}</span>
                                </div>
                                <div
                                    class="auth-password-reset-text"
                                    @click="onForgotPasswordClick()"
                                >
                                    Forgot Password?
                                </div>
                            </span>
                            <span class="authInputSubmitWrapper">
                                <div
                                    class="authInputSubmitBtn noUserSelect"
                                    @click="onLoginClick()"
                                >
                                    <span v-if="reauthentication"
                                        >Reauthenticate</span
                                    >
                                    <span v-else>Login</span>
                                </div>
                            </span>
                        </div>
                    </form>
                    <Divider v-if="!reauthentication" align="center">
                        <b>Or Sign in With</b>
                    </Divider>
                    <div class="oauth-btn-wrapper">
                        <div
                            v-if="!reauthentication"
                            class="auth-window-oauth-wrapper auth-window-middle-section"
                        >
                            <div
                                class="auth-window-oauth-google auth-window-oauth noUserSelect"
                                @click="submitGoogle()"
                            >
                                <div class="auth-window-oauth-img-wrapper">
                                    <img
                                        class="auth-window-oauth-img"
                                        src="/assets/images/googleLogo.svg"
                                    />
                                </div>
                                <div class="auth-window-oauth-text-wrapper">
                                    <span class="auth-window-oauth-text"
                                        >Google</span
                                    >
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="!reauthentication"
                            class="auth-window-oauth-wrapper auth-window-middle-section"
                        >
                            <div
                                class="auth-window-oauth-facebook auth-window-oauth noUserSelect"
                                @click="submitFacebook()"
                            >
                                <div class="auth-window-oauth-img-wrapper">
                                    <img
                                        class="auth-window-oauth-img"
                                        src="/assets/images/facebookLogo.svg"
                                    />
                                </div>
                                <div class="auth-window-oauth-text-wrapper">
                                    <span class="auth-window-oauth-text"
                                        >Facebook</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>

                    <span class="authInputAcknowledgementWrapper">
                        <div class="authInputAcknowledgement">
                            By creating an account, you acknowledge that you
                            have understood and agreed to our
                            <router-link :to="{ name: 'PrivacyPolicy' }"
                                >Privacy Policy</router-link
                            >
                            and
                            <router-link :to="{ name: 'Tos' }"
                                >Terms of Use</router-link
                            >
                        </div>
                    </span>
                </div>
                <div class="auth-window-bottom">
                    <div
                        v-if="!reauthentication"
                        class="auth-window-login-mode-wrapper"
                    >
                        <div>
                            Don't have an account?
                            <span
                                class="auth-window-login-mode-btn"
                                @click="onModeClick('signup')"
                                >Register Now!</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else-if="mode == 'signup'"
                id="auth-signup"
                class="authWindowWrapper"
            >
                <div class="auth-window-top">
                    <span class="authWindowTitle"
                        >Create Freshy Recipes Account</span
                    >
                </div>
                <div class="auth-window-middle">
                    <form id="auth-window-create" autocomplete="off">
                        <transition name="fade" mode="out-in">
                            <div
                                v-if="userCreateStep == 0"
                                class="auth-window-create-step-one auth-window-middle-section"
                            >
                                <span
                                    class=" authInputWrapper authInputEmailWrapper"
                                >
                                    <label for="useremail">Email</label>
                                    <input-text
                                        id="useremail"
                                        class="authInput"
                                        v-model="userEmail"
                                        type="text"
                                        :class="{
                                            authInputInvalid:
                                                submitErrors.createEmail,
                                        }"
                                        @keypress.enter="
                                            onUserCreateContinueStep1
                                        "
                                    />
                                    <div class="authInputErrorMsg">
                                        <span v-if="submitErrors.createEmail">{{
                                            submitErrors.createEmail
                                        }}</span>
                                    </div>
                                </span>
                                <span class="authInputSubmitWrapper">
                                    <div
                                        class="authInputSubmitBtn noUserSelect"
                                        @click="onUserCreateContinueStep1"
                                    >
                                        <span>Continue</span>
                                    </div>
                                </span>
                                <span class="authInputAcknowledgementWrapper">
                                    <div class="authInputAcknowledgement">
                                        By creating an account, you acknowledge
                                        that you have understood and agreed to
                                        our
                                        <router-link
                                            :to="{ name: 'PrivacyPolicy' }"
                                            >Privacy Policy</router-link
                                        >
                                        and
                                        <router-link :to="{ name: 'Tos' }"
                                            >Terms of Use</router-link
                                        >
                                    </div>
                                </span>
                                <Divider
                                    v-if="!reauthentication"
                                    align="center"
                                >
                                    <b>OR</b>
                                </Divider>
                                <div class="auth-window-oauth-register-title">
                                    <span
                                        class="auth-window-oauth-register-title-text"
                                        >Sign up with</span
                                    >
                                </div>
                                <div class="auth-window-oauth-outer-wrapper">
                                    <div
                                        class="auth-window-oauth-wrapper auth-window-middle-section"
                                    >
                                        <div
                                            class="auth-window-oauth-google auth-window-oauth noUserSelect"
                                            @click="submitGoogle()"
                                        >
                                            <div
                                                class="auth-window-oauth-img-wrapper"
                                            >
                                                <img
                                                    class="auth-window-oauth-img"
                                                    src="/assets/images/googleLogo.svg"
                                                />
                                            </div>
                                            <div
                                                class="auth-window-oauth-text-wrapper"
                                            >
                                                <span
                                                    class="auth-window-oauth-text"
                                                    >Google</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="auth-window-oauth-wrapper auth-window-middle-section"
                                    >
                                        <div
                                            class="auth-window-oauth-facebook auth-window-oauth noUserSelect"
                                            @click="submitFacebook()"
                                        >
                                            <div
                                                class="auth-window-oauth-img-wrapper"
                                            >
                                                <img
                                                    class="auth-window-oauth-img"
                                                    src="/assets/images/facebookLogo.svg"
                                                />
                                            </div>
                                            <div
                                                class="auth-window-oauth-text-wrapper"
                                            >
                                                <span
                                                    class="auth-window-oauth-text"
                                                    >Facebook</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-else-if="userCreateStep == 1"
                                class="auth-window-create-step-two auth-window-middle-section"
                            >
                                <span class=" authInputWrapper">
                                    <label for="username_first"
                                        >First Name</label
                                    >
                                    <input-text
                                        id="username_first"
                                        class="authInput"
                                        v-model="userNameFirst"
                                        type="text"
                                        :class="{
                                            authInputInvalid:
                                                submitErrors.createFirstName,
                                        }"
                                        @keypress.enter="
                                            onUserCreateContinueStep1()
                                        "
                                    />
                                    <div class="authInputErrorMsg">
                                        <span
                                            v-if="submitErrors.createFirstName"
                                            >{{
                                                submitErrors.createFirstName
                                            }}</span
                                        >
                                    </div>
                                </span>
                                <span class=" authInputWrapper">
                                    <label for="username_last">Last Name</label>
                                    <input-text
                                        id="username_last"
                                        class="authInput"
                                        v-model="userNameLast"
                                        type="text"
                                        :class="{
                                            authInputInvalid:
                                                submitErrors.createLastName,
                                        }"
                                        @keypress.enter="
                                            onUserCreateContinueStep1()
                                        "
                                    />
                                    <div class="authInputErrorMsg">
                                        <span
                                            v-if="submitErrors.createLastName"
                                            >{{
                                                submitErrors.createLastName
                                            }}</span
                                        >
                                    </div>
                                </span>
                                <span class="authInputWrapper">
                                    <label for="create-password"
                                        >Password</label
                                    >
                                    <password
                                        id="create-password"
                                        inputClass="authInput"
                                        style="width: 100%;"
                                        v-model="userPass"
                                        autocomplete="new-password"
                                        :class="{
                                            authInputInvalid:
                                                submitErrors.createPassword,
                                        }"
                                        @keypress.enter="onCreateAccountClick()"
                                    >
                                        <template #header>
                                            <h4
                                                class="authPasswordTooltipTitle"
                                            >
                                                Pick a Password
                                            </h4>
                                        </template>
                                        <template #footer>
                                            <Divider />
                                            <p class="">
                                                Password Requirements
                                            </p>
                                            <ul class="p-pl-2 p-ml-2 p-mt-0">
                                                <li>At least one lowercase</li>
                                                <li>At least one uppercase</li>
                                                <li>At least one numeric</li>
                                                <li>Minimum 8 characters</li>
                                            </ul>
                                        </template>
                                    </password>
                                    <div class="authInputErrorMsg">
                                        <span
                                            v-if="submitErrors.createPassword"
                                            >{{
                                                submitErrors.createPassword
                                            }}</span
                                        >
                                    </div>
                                </span>
                                <span class="authInputWrapper">
                                    <label for="create-verifyPassword"
                                        >Verify Password</label
                                    >
                                    <password
                                        id="create-verifyPassword"
                                        inputClass="authInput"
                                        style="width: 100%;"
                                        v-model="userVerifyPass"
                                        type="password"
                                        :feedback="false"
                                        autocomplete="new-password"
                                        :class="{
                                            authInputInvalid:
                                                submitErrors.createVerifyPassword,
                                        }"
                                        @keypress.enter="onCreateAccountClick()"
                                    />
                                    <div class="authInputErrorMsg">
                                        <span
                                            v-if="
                                                submitErrors.createVerifyPassword
                                            "
                                            >{{
                                                submitErrors.createVerifyPassword
                                            }}</span
                                        >
                                    </div>
                                </span>
                                <span class="authInputSubmitWrapper">
                                    <div
                                        class="authInputSubmitBtn noUserSelect"
                                        @click="onCreateAccountClick()"
                                    >
                                        <span>Create Account</span>
                                    </div>
                                </span>
                                <div
                                    v-if="userCreateStep > 0"
                                    class="auth-window-user-create-back-btn-wrapper"
                                >
                                    <div
                                        class="auth-window-user-create-back-btn noUserSelect"
                                        @click="userCreateStep--"
                                    >
                                        Back
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </form>
                </div>
                <div class="auth-window-bottom">
                    <div class="auth-window-login-mode-wrapper">
                        <div>
                            Already have an account?
                            <span
                                class="auth-window-login-mode-btn"
                                @click="onModeClick('signin')"
                                >Login Now!</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else-if="mode == 'updatePassword'"
                id="auth-update-password"
                class="authWindowWrapper"
            >
                <div class="auth-window-top">
                    <span class="authWindowTitle">Reset Password</span>
                </div>
                <div class="auth-window-middle">
                    <form id="auth-window-update-password" autocomplete="off">
                        <transition name="fade" mode="out-in">
                            <div
                                class="auth-window-create-step-two auth-window-middle-section"
                            >
                                <span class="authInputWrapper">
                                    <label for="update-password"
                                        >New Password</label
                                    >
                                    <password
                                        id="update-password"
                                        inputClass="authInput"
                                        style="width: 100%;"
                                        v-model="userPass"
                                        autocomplete="new-password"
                                        :class="{
                                            authInputInvalid:
                                                submitErrors.createPassword,
                                        }"
                                        @keypress.enter="
                                            onPasswordChangeSubmit()
                                        "
                                    >
                                        <template #header>
                                            <h4
                                                class="authPasswordTooltipTitle"
                                            >
                                                Pick a Password
                                            </h4>
                                        </template>
                                        <template #footer>
                                            <Divider />
                                            <p class="">
                                                Password Requirements
                                            </p>
                                            <ul class="p-pl-2 p-ml-2 p-mt-0">
                                                <li>At least one lowercase</li>
                                                <li>At least one uppercase</li>
                                                <li>At least one numeric</li>
                                                <li>Minimum 8 characters</li>
                                            </ul>
                                        </template>
                                    </password>
                                    <div class="authInputErrorMsg">
                                        <span
                                            v-if="submitErrors.createPassword"
                                            >{{
                                                submitErrors.createPassword
                                            }}</span
                                        >
                                    </div>
                                </span>
                                <span class="authInputWrapper">
                                    <label for="update-verifyPassword"
                                        >Verify New Password</label
                                    >
                                    <password
                                        id="update-verifyPassword"
                                        inputClass="authInput"
                                        style="width: 100%;"
                                        v-model="userVerifyPass"
                                        type="password"
                                        :feedback="false"
                                        autocomplete="new-password"
                                        :class="{
                                            authInputInvalid:
                                                submitErrors.createVerifyPassword,
                                        }"
                                        @keypress.enter="
                                            onPasswordChangeSubmit()
                                        "
                                    />
                                    <div class="authInputErrorMsg">
                                        <span
                                            v-if="
                                                submitErrors.createVerifyPassword
                                            "
                                            >{{
                                                submitErrors.createVerifyPassword
                                            }}</span
                                        >
                                    </div>
                                </span>
                                <span class="authInputSubmitWrapper">
                                    <div
                                        class="authInputSubmitBtn noUserSelect"
                                        @click="onPasswordChangeSubmit()"
                                    >
                                        <span>Update Password</span>
                                    </div>
                                </span>
                            </div>
                        </transition>
                    </form>
                </div>
            </div>
            <div
                v-else-if="mode == 'signout'"
                id="auth-signout"
                class="authWindowWrapper"
            >
                <div class="auth-window-top">
                    <span class="authWindowTitle">Logout</span>
                </div>
                <div class="auth-window-middle">
                    <div class="auth-window-logout-btn" @click="doLogout()">
                        Logout
                    </div>
                </div>
                <div class="auth-window-bottom"></div>
            </div>
            <div
                v-else-if="mode == 'forgotPassword'"
                id="auth-password-reset"
                class="authWindowWrapper"
            >
                <div class="auth-window-top">
                    <span class="authWindowTitle"
                        >Recover Forgotten Password</span
                    ><br />
                </div>
                <div class="auth-window-middle">
                    <div class="auth-reset-message">
                        <span v-if="!resetEmailChecked"
                            >Please enter your email</span
                        >
                        <span v-else-if="resetEmailChecked"
                            >Please check your provided email for a link to
                            reset your password</span
                        >
                    </div>
                    <div class="auth-reset-input">
                        <div
                            v-if="!resetEmailChecked"
                            class="auth-reset-email-input-wrapper auth-reset-input-wrapper"
                        >
                            <input-text
                                type="text"
                                class="authInput"
                                v-model="resetUserEmail"
                                autocomplete="off"
                                :class="{
                                    authInputInvalid: submitErrors.resetEmail,
                                }"
                                @keydown.enter="onResetEmailSubmit()"
                            />
                            <div class="authInputErrorMsg">
                                <span v-if="submitErrors.resetEmail">{{
                                    submitErrors.resetEmail
                                }}</span>
                            </div>
                            <div
                                class="auth-reset-btn"
                                @click="onResetEmailSubmit()"
                            >
                                Submit
                            </div>
                        </div>
                    </div>
                    <div class="auth-reset-message">
                        <div class="authInputErrorMsg authErrorAltAccount">
                            <span v-if="submitErrors.resetEmailAltAccount">{{
                                submitErrors.resetEmailAltAccount
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="auth-window-bottom">
                    <div
                        class="auth-reset-back-btn noUserSelect"
                        @click="onBackToLoginClick()"
                    >
                        Return to Login
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Divider from "primevue/divider";
import Toast from "primevue/toast";
import userMixin from "../../../store/mixins/user";
import { mapActions } from "vuex";
import {
    checkIfEmailExists,
    sendResetPasswordEmail,
    FIREBASE_AUTH_ERRORS,
    FIREBASE_AUTH_PROVIDERS,
    changeUserPassword,
    FIREBASE_AUTH_PROVIDER_IDS,
    checkPasswordResetCode,
    confirmPasswordReset,
} from "../../../auth/firebase";
// import firebaseui from "firebaseui"

export default {
    name: "auth-window",
    props: [
        "redirectOnLogin",
        "redirectOnLogout",
        "isCreateAccount",
        "isReauthentication",
        "isUpdatePassword",
        "isResetPassword",
        "code",
    ],
    mixins: [userMixin],
    components: {
        InputText,
        Password,
        Divider,
        Toast,
    },
    data() {
        return {
            mode: null,
            userCreateStep: 0,
            userNameFirst: "",
            userNameLast: "",
            userEmail: "",
            userPass: "",
            userVerifyPass: "",
            resetUserEmail: "",
            resetUserCode: "",
            resetUserPass: "",
            resetUserVerifyPass: "",
            resetEmailChecked: false,
            strongPassRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
            emailRegex: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            submitErrors: {},
            reauthentication: false,
        };
    },
    computed: {
        checkPassStrength() {
            if (this.userPass && this.userPass != "") {
                return this.strongPassRegex.test(this.userPass);
            }
            return false;
        },
        checkSamePass() {
            return this.userPass == this.userVerifyPass;
        },
        checkResetPassStrength() {
            if (this.resetUserPass && this.resetUserPass != "") {
                return this.strongPassRegex.test(this.resetUserPass);
            }
            return false;
        },
        checkResetSamePass() {
            return this.resetUserPass == this.resetUserVerifyPass;
        },
        redirectLogin() {
            if (
                this.$route &&
                this.$route.params &&
                this.$route.params.redirectLogin
            ) {
                try {
                    const rol = JSON.parse(this.$route.params.redirectLogin);
                    return rol;
                } catch {
                    return null;
                }
            }
            if (this.redirectOnLogin) {
                try {
                    const rol = this.redirectOnLogin;
                    return rol;
                } catch {
                    return null;
                }
            } else return null;
        },
        redirectLogout() {
            if (
                this.$route &&
                this.$route.params &&
                this.$route.params.redirectLogout
            ) {
                try {
                    return JSON.parse(this.$route.params.redirectLogin);
                } catch {
                    return null;
                }
            }
            if (this.redirectOnLogout) {
                try {
                    return this.redirectOnLogout;
                } catch {
                    return null;
                }
            } else return null;
        },
    },
    mounted() {
        if (this.isCreateAccount) {
            this.mode = "signup";
        } else if (this.isReauthentication) {
            this.reauthentication = true;
            this.mode = "signin";
        } else if (this.isUpdatePassword) {
            this.mode = "updatePassword";
        } else if (this.isResetPassword) {
            this.mode = "updatePassword";
        } else if (this.$store.state.user.user) {
            if (this.redirectLogout) this.$router.push(this.redirectLogout);
            else this.mode = "signout";
        } else {
            this.mode = "signin";
            // this.fireGoogleOneTapPrompt()
        }
    },
    watch: {
        /*
        mode(newVal) {
            if(newVal == "signin"){
                this.fireGoogleOneTapPrompt()
            }
        },
        */
    },
    methods: {
        ...mapActions([
            "createUserWithEmail",
            "loginWithEmail",
            "loginWithGoogle",
            "loginWithFacebook",
            "sendEmailVerification",
            "reauthenticate",
            "logout",
        ]),
        async onLoginClick() {
            // Existing User Signin
            this.submitErrors = {};
            if (
                this.userEmail &&
                this.emailRegex.test(this.userEmail) &&
                this.userPass
            ) {
                const exists = await checkIfEmailExists(this.userEmail);

                if (!exists) {
                    this.submitErrors.loginEmail = "Email not found";
                    return;
                }

                if (this.reauthentication) {
                    // Reauth Flow
                    const res = await this.reauthenticate({
                        provider: FIREBASE_AUTH_PROVIDERS.email,
                        email: this.userEmail,
                        password: this.userPass,
                    });
                    if (res.error) {
                        // Login error, handle
                        switch (res.error) {
                            case FIREBASE_AUTH_ERRORS.userNotFound:
                                this.submitErrors.loginEmail =
                                    "Email Not Found";
                                break;
                            case FIREBASE_AUTH_ERRORS.wrongPassword:
                                this.submitErrors.loginPassword =
                                    "Incorrect Password";
                                break;
                            case FIREBASE_AUTH_ERRORS.tooManyRequests:
                                this.$toast.add({
                                    severity: "error",
                                    summary: "Too Many attempts",
                                    detail:
                                        "Too many attempts, please wait a bit and try again.",
                                    group: "tc",
                                    life: 3000,
                                });
                                break;
                        }
                        return false;
                    }
                    this.$emit("reauthenticated", true);
                    return true;
                }
                // Login Flow
                const res = await this.loginWithEmail({
                    email: this.userEmail,
                    password: this.userPass,
                });
                if (res.error) {
                    // Login error, handle
                    switch (res.error) {
                        case FIREBASE_AUTH_ERRORS.userNotFound:
                            this.submitErrors.loginEmail = "Email Not Found";
                            break;
                        case FIREBASE_AUTH_ERRORS.wrongPassword:
                            this.submitErrors.loginPassword =
                                "Incorrect Password";
                            break;
                        case FIREBASE_AUTH_ERRORS.tooManyRequests:
                            this.$toast.add({
                                severity: "error",
                                summary: "Too Many attempts",
                                detail:
                                    "Too many attempts, please wait a bit and try again.",
                                group: "tc",
                                life: 3000,
                            });
                            break;
                    }
                } else if (this.$store.state.user.user) {
                    this.$emit("login", this.$store.state.user.user);

                    if (this.redirectLogin)
                        this.$router.push(this.redirectLogin);
                    else {
                        this.mode = "signout";
                    }
                }
            } else {
                if (!this.userEmail) {
                    this.submitErrors.loginEmail =
                        "Must enter an email address";
                } else if (!this.emailRegex.test(this.userEmail)) {
                    this.submitErrors.loginEmail = "Invalid email address";
                }

                if (!this.userPass) {
                    this.submitErrors.loginPassword = "Must enter a password";
                }
            }
        },
        async onCreateAccountClick() {
            // Create New User
            this.submitErrors = {};
            if (
                this.checkPassStrength &&
                this.checkSamePass &&
                this.userNameFirst &&
                this.userNameLast
            ) {
                const res = await this.createUserWithEmail({
                    email: this.userEmail,
                    password: this.userPass,
                    firstName: this.userNameFirst,
                    lastName: this.userNameLast,
                });
                if (res) {
                    // Success
                    this.sendEmailVerification();
                    this.$emit("login", this.$store.state.user.user);
                    if (this.redirectLogin)
                        this.$router.push(this.redirectLogin);
                }
            } else {
                if (!this.checkPassStrength) {
                    // Alert too weak password
                    this.submitErrors.createPassword =
                        "Must have strong password";
                } else if (!this.checkSamePass) {
                    // Alert not verified password
                    this.submitErrors.createVerifyPassword =
                        "Passwords must match";
                }
                if (!this.userNameFirst) {
                    // First Name Required
                    this.submitErrors.createFirstName =
                        "First name is required";
                }
                if (!this.userNameLast) {
                    // Last Name required
                    this.submitErrors.createLastName = "Last name is required";
                }
            }

            if (this.$store.state.user.user) {
                this.$emit("login", this.$store.state.user.user);
                this.submitErrors = {};
                if (this.redirectLogin) this.$router.push(this.redirectLogin);
                else this.mode = "signout";
            }
        },
        async submitGoogle() {
            await this.loginWithGoogle();
            this.$emit("login", this.$store.state.user.user);
            if (this.redirectLogin) {
                this.$router.push(this.redirectLogin);
            } else {
                this.mode = "signout";
            }
        },
        async submitFacebook() {
            await this.loginWithFacebook();
            this.$emit("login", this.$store.state.user.user);
            if (this.redirectLogin) {
                this.$router.push(this.redirectLogin);
            } else {
                this.mode = "signout";
            }
        },
        async doLogout() {
            this.submitErrors = {};
            const oldUser = this.$store.state.user.user;
            await this.logout();
            this.$emit("logout", oldUser);
            if (this.redirectLogout)
                this.$router.push(this.redirectLogout, oldUser);
            else this.mode = "signin";
        },
        onBackToLoginClick() {
            this.mode = "signin";
            this.resetUserEmail = "";
            this.resetUserCode = "";
            this.resetUserPass = "";
            this.resetUserVerifyPass = "";
            this.resetEmailChecked = false;
            this.submitErrors = {};
        },
        onForgotPasswordClick() {
            this.mode = "forgotPassword";
            if (this.userEmail) this.resetUserEmail = this.userEmail;
        },
        async onResetEmailSubmit() {
            this.submitErrors = {};

            if (this.resetUserEmail) {
                if (!this.emailRegex.test(this.resetUserEmail)) {
                    this.submitErrors.resetEmail =
                        "Please enter a valid email address";
                    return;
                }

                const exists = await checkIfEmailExists(
                    this.resetUserEmail,
                    true
                );
                if (exists && exists.data && exists.data.includes("password")) {
                    // Account with password exists, start reset
                    this.resetUserCode = "";
                    this.resetUserPass = "";
                    this.resetUserVerifyPass = "";
                    await sendResetPasswordEmail(this.resetUserEmail);
                    this.resetEmailChecked = true;
                } else if (exists && exists.data && exists.data.length > 0) {
                    // Account from third party provider, send error
                    this.submitErrors.resetEmailAltAccount =
                        "This email is associated with a third-party login provider (i.e. Google or Facebook). Please refer to your account provider to change your password.";

                    for (const i in exists.data) {
                        if (FIREBASE_AUTH_PROVIDER_IDS[exists.data[i]]) {
                            let check = false;
                            if (
                                FIREBASE_AUTH_PROVIDER_IDS[exists.data[i]] ==
                                FIREBASE_AUTH_PROVIDERS.google
                            ) {
                                check = true;
                                await this.loginWithGoogle();
                            } else if (
                                FIREBASE_AUTH_PROVIDER_IDS[exists.data[i]] ==
                                FIREBASE_AUTH_PROVIDERS.facebook
                            ) {
                                check = true;
                                await this.loginWithFacebook();
                            }

                            if (check) break;
                        }
                    }
                } else {
                    // There is no account with email/password combination that exists for this email
                    this.submitErrors.resetEmail =
                        "There is no account set up with this email";
                }
            } else {
                this.submitErrors.resetEmail = "Must enter an email address";
            }
        },
        onPasswordChangeSubmit() {
            if (this.isUpdatePassword) this.onUpdatePassword();
            else if (this.isResetPassword && this.code)
                this.onResetPassword(this.code);
        },
        async onUpdatePassword() {
            if (this.checkPassStrength && this.checkSamePass) {
                const res = await changeUserPassword(this.userPass);
                if (res) {
                    // Success
                    this.$emit("passwordUpdated", true);
                } else {
                    // error
                    console.error("Error resetting password", res);
                }
            } else if (!this.checkPassStrength) {
                // Alert too weak password
                this.submitErrors.createPassword = "Must have strong password";
            } else if (!this.checkSamePass) {
                // Alert not verified password
                this.submitErrors.createVerifyPassword = "Passwords must match";
            }
        },
        async onResetPassword(code) {
            if (this.checkPassStrength && this.checkSamePass && code) {
                const res = await checkPasswordResetCode(code);
                if (res && res.success) {
                    // Success, run confirm password function
                    const passReset = await confirmPasswordReset(
                        code,
                        this.userPass
                    );

                    if (passReset && passReset.success) {
                        // password reset was successful
                        this.$toast.add({
                            severity: "success",
                            group: "top-center",
                            summary: "Password Reset",
                            detail:
                                "Your password has been reset. You may now use your new password.",
                            life: 5000,
                        });
                        this.$emit("resetPasswordSuccess", true);
                    } else if (
                        passReset &&
                        !passReset.success &&
                        passReset.data
                    ) {
                        console.error(
                            "Error confirming password reset",
                            passReset
                        );
                        // Something went wrong
                        const failCode = passReset.data.code;
                        if (failCode) {
                            switch (failCode) {
                                case FIREBASE_AUTH_ERRORS.invalidActionCode:
                                case FIREBASE_AUTH_ERRORS.expiredActionCode:
                                    this.$toast.add({
                                        severity: "error",
                                        group: "top-center",
                                        summary: "Password Not Reset",
                                        detail:
                                            "This link is either malformed or expired.",
                                        life: 5000,
                                    });
                                    break;
                                case FIREBASE_AUTH_ERRORS.userNotFound:
                                case FIREBASE_AUTH_ERRORS.userDisabled:
                                    this.$toast.add({
                                        severity: "error",
                                        group: "top-center",
                                        summary: "Password Not Reset",
                                        detail:
                                            "This link is not connected to a known account.",
                                        life: 5000,
                                    });
                            }
                        }
                        this.$toast.add({
                            severity: "error",
                            group: "top-center",
                            summary: "Password Not Reset",
                            detail:
                                "Something went wrong with resetting your password. Please try again later.",
                            life: 5000,
                        });
                    } else {
                        this.$toast.add({
                            severity: "error",
                            group: "top-center",
                            summary: "Password Not Reset",
                            detail:
                                "Cannot reset password. Please try again later",
                            life: 5000,
                        });
                    }
                } else if (res && !res.success && res.data) {
                    // error
                    console.error("Error resetting password", res);
                    const failCode = res.data.code;
                    if (failCode) {
                        switch (failCode) {
                            case FIREBASE_AUTH_ERRORS.invalidActionCode:
                            case FIREBASE_AUTH_ERRORS.expiredActionCode:
                                this.$toast.add({
                                    severity: "error",
                                    group: "top-center",
                                    summary: "Password Not Reset",
                                    detail:
                                        "This link is either malformed or expired.",
                                    life: 5000,
                                });
                                break;
                            case FIREBASE_AUTH_ERRORS.userNotFound:
                            case FIREBASE_AUTH_ERRORS.userDisabled:
                                this.$toast.add({
                                    severity: "error",
                                    group: "top-center",
                                    summary: "Password Not Reset",
                                    detail:
                                        "This link is not connected to a known account.",
                                    life: 5000,
                                });
                        }
                    } else {
                        this.$toast.add({
                            severity: "error",
                            group: "top-center",
                            summary: "Password Not Reset",
                            detail:
                                "Cannot reset password. Please try again later",
                            life: 5000,
                        });
                    }
                }
            } else if (!this.checkPassStrength) {
                // Alert too weak password
                this.submitErrors.createPassword = "Must have strong password";
            } else if (!this.checkSamePass) {
                // Alert not verified password
                this.submitErrors.createVerifyPassword = "Passwords must match";
            }
        },
        onModeClick(mode) {
            this.userCreateStep = 0;
            this.userNameFirst = "";
            this.userNameLast = "";
            this.userPass = "";
            this.userVerifyPass = "";
            this.submitErrors = {};
            this.mode = mode;
        },
        async onUserCreateContinueStep1(e) {
            e.preventDefault();
            e.stopPropagation();
            this.submitErrors = {};

            if (!this.userEmail) {
                // Email required
                this.submitErrors.createEmail = "Email is required";
            } else if (!this.emailRegex.test(this.userEmail)) {
                // Not a valid email
                this.submitErrors.createEmail = "Invalid email address";
            } else {
                const exists = await checkIfEmailExists(this.userEmail);
                if (exists)
                    this.submitErrors.createEmail = "Email already in use";
            }

            if (Object.keys(this.submitErrors).length == 0) {
                this.userCreateStep = 1;
            }
        },
    },
};
</script>

<style scoped src="./authWindow.css"></style>
