<template>
    <div class="AIrecipeCardRoot p-shadow-1">
        <div v-if="!hideSave && recipe" class="saveBtnWrapper">
            <recipe-save-btn
                :recipe="recipe"
                :showRemove="showRemoveBtn"
            ></recipe-save-btn>
        </div>
        <router-link
            :to="
                `/recipe/${recipe.uuid ||
                    (recipe.data && recipe.data.uuid
                        ? recipe.data.uuid
                        : null)}`
            "
            v-if="recipe"
            class="AIrecipe-card-wrapper"
            @click="onClick()"
        >
            <div
                v-if="thisImage"
                class="AIrecipe-card-left"
                :class="{ 'aicleft': thisImage, 'p-d-none': !thisImage }"
            >
                <img class="AIrecipe-card-img" :src="thisImage" />
            </div>
            <div
                class="AIrecipe-card-right"
                :class="{ 'aicright': thisImage, 'p-col-12': !thisImage }"
            >
                <div class="AIrecipe-card-data">
                    <div class="AIrecipe-card-data-wrapper">
                        <div class="cookData">
                            <div v-if="complexity" class="cookDifficulty">{{ (complexity == '1' || complexity == '2') ? "Easy" :  (complexity == "3" || complexity == "4") ? "Medium" : "Difficult"  }}</div>
                            <div class="cookTime"><span class="cookTimeLabel">Cooks in</span>
                                <span class="cookTimeData">{{ cooktime }}</span></div>
                        </div>
                        <div
                            v-if="formattedTitle"
                            class="AIrecipe-card-title recipe-card-data"
                        >

                            <span v-snip="2" :title="formattedTitle">{{
                                formattedTitle
                            }}</span>

                          <div
                              class="detail-meta-item"
                              v-if="ratings"
                          >
                            <div class="detail-meta-item-label">
                              Rating
                            </div>
                            <div
                                id="rating-stars"
                                class="detail-meta-item-data"
                            >
                              <div id="empty-star">
                                <i class="pi pi-star"></i>
                                <i class="pi pi-star"></i>
                                <i class="pi pi-star"></i>
                                <i class="pi pi-star"></i>
                                <i class="pi pi-star"></i>
                              </div>

                              <div
                                  id="fill-star"
                                  class="fillpercent"
                                  :style="{
                                                        width:
                                                            ratings + '%',
                                                    }"
                              >
                                <i class="pi pi-star"></i>
                                <i class="pi pi-star"></i>
                                <i class="pi pi-star"></i>
                                <i class="pi pi-star"></i>
                                <i class="pi pi-star"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>

                </div>
            </div>
        </router-link>
    </div>
                  <div class="aiTag"
                  >
                        <div class="aiTagItem"  v-for="(tag, index) in tags"
                             :key="index"> <span @click.stop="searchTag(tag, $event)">{{ tag }}</span></div>

                    </div>

</template>

<script>
import recipeMixin from "../componentMixins/recipeMixin";
import RecipeSaveBtn from "../recipeSaveBtn/RecipeSaveBtn";

export default {
    name: "ai-recipe-card",
    mixins: [recipeMixin],
    props: [
        "recipe",
        "image",
        "title",
        "calories",
        "carbs",
        "cooktime",
        "complexity",
        "tags",
        "url",
        "hideSave",
        "showRemove",
        "createdOn",
        "ratings"
    ],
    components: {
        RecipeSaveBtn,
    },
    data() {
        return {}
    },
    computed: {
        thisImage() {
            return this.image || null;
        },
        formattedCalories() {
            if (!isNaN(this.calories)) return parseInt(this.calories);
            return this.calories;
        },
        formattedCarbs() {
            if (!isNaN(this.carbs)) return parseInt(this.carbs);
            return this.carbs;
        },
        formattedCooktime() {
            return this.formatTimeFromMinutes(this.cooktime);
        },
        formattedTitle() {
            if (this.title) return this.title;
            if (this.url) return this.url;
            return null;
        },
        showRemoveBtn() {
            if (this.showRemove) return this.showRemove;
            return null;
        },
    },
    mounted() {
    },
    methods: {
        onClick() {
            this.$emit("onClick", this.recipe);
          this.$router.push({
            path: `/recipe/${this.data.uuid || this.data.data.uuid}`,
          });
        },
      searchTag(tag, event) {
        event.stopPropagation();
        const url = new URL(window.location.href);
        url.searchParams.set("q", tag);
        window.history.pushState(
            {},
            `Cooking.freshy.com - Search: ${this.enteredQuery}`,
            url.toString()
        );
        this.$router.push({
          name: "Search",
          query: {
            q: tag,
          },
        });
      },
    },
};
</script>

<style scoped src="./AIrecipeCard.css"></style>
