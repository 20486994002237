<template>
    <div id="home-root">
        <div id="home-header">
            <transition name="ultrafastFade" mode="out-in">
                <Header
                    class="home-header-wrapper"
                    :home="showBigHeader"
                ></Header>
            </transition>
            <FirstRun v-if="showFirstRun && isNewtab" />
        </div>

        <div id="home-body">
            <div id="adnt-wrapper" v-if="isNewtab">
                <div class="outer-wrapper">
                    <div class="adnt-content-wrapper p-grid">
                        <div id="idea-card-wrapper" class="p-col-12">
                            <IdeaListNewtab :maxNum="3"></IdeaListNewtab>
                        </div>
                    </div>
                </div>
            </div>

            <div id="home-body-wrapper" class="p-grid" v-if="!isNewtab">
                <div class="p-col-12 p-lg-9 p-md-8" id="home-body-content">
                    <FeaturedArticles></FeaturedArticles>
                </div>

                <div class="p-col-12 p-lg-3 p-md-4" id="home-body-sidebar">
                    <IdeaList></IdeaList>
                </div>
            </div>

            <div id="home-hero" class="home-body-section">
                <LatestIdea v-if="!isNewtab"></LatestIdea>

                <div
                    v-if="loadingCarousel"
                    class="home-hero-loader home-hero-section"
                >
                    <Skeleton width="100%" height="100%" animation="wave" />
                </div>

                <div
                    v-else-if="!loadingCarousel && carouselData"
                    class="home-hero-carousel home-hero-section"
                >
                    <Carousel
                        :value="carouselData"
                        :circular="false"
                        :numVisible="4"
                        :responsiveOptions="getCarouselOptions(carouselData)"
                    >
                        <template #header>
                            <div
                                class="home-header-title hero-carousel-header-title"
                            >
                                <h2>Popular Recipes</h2>
                                <h3>
                                    Delicious 5 star recipes from top recipe
                                    sites around the web
                                </h3>
                            </div>
                        </template>
                        <template #item="recipe">
                            <feed-card
                                v-if="
                                    recipe.data &&
                                        recipe.data.dataReturnType &&
                                        recipe.data.dataReturnType == 'yfeed'
                                "
                                :feedData="recipe.data"
                            />
                            <recipe-card
                                v-else-if="recipe.data"
                                :recipe="recipe.data"
                                :title="getCarouselTitle(recipe.data)"
                                :image="getCarouselImage(recipe.data)"
                                :calories="getCarouselCalories(recipe.data)"
                                :carbs="recipe?.data?.recipedata?.resultCarbs"
                                :cooktime="recipe?.data?.recipedata?.total_time"
                                :url="recipe.data.url"
                                @onClick="onRecipeClick"
                            />
                        </template>
                    </Carousel>
                </div>
                <div
                    v-if="activeUser && sizedRecipeList"
                    class="home-hero-carousel home-hero-section"
                >
                    <Carousel
                        :value="sizedRecipeList"
                        :key="sizedRecipeList.length"
                        :circular="false"
                        :numVisible="getCarouselVisible(sizedRecipeList)"
                        :responsiveOptions="getCarouselOptions(sizedRecipeList)"
                    >
                        <template #header>
                            <div
                                class="home-header-title hero-carousel-header-title"
                            >
                                <h2>My Recipe Book</h2>
                                <h3>Pick a saved recipe to cook today</h3>

                                <Button
                                    id="book-button"
                                    label="View Recipe Book"
                                    class="p-button-outlined p-button-lg"
                                    @click="
                                        $router.push({ name: 'RecipeBook' })
                                    "
                                />
                            </div>
                        </template>
                        <template #item="recipe">
                            <recipe-card
                                v-if="
                                    recipe &&
                                        recipe.data &&
                                        !recipe.data.isSubstitute
                                "
                                :recipe="recipe.data"
                                :title="recipe.data.title"
                                :image="recipe.data.image"
                                :calories="recipe.data.calories"
                                :carbs="recipe.data.carbohydrates"
                                :cooktime="recipe.data.cooktime"
                                :url="recipe.data.source"
                                @onClick="onRecipeBookClick"
                            />
                            <recipe-card-placeholder v-else />
                        </template>
                    </Carousel>
                </div>
                <recommendation-carousel
                    v-if="
                        activeUser &&
                            activeUser.recipeList &&
                            Object.keys(activeUser.recipeList).length
                    "
                    :seedRecipes="activeUser.recipeList"
                    :subheader="
                        `Delicious recommendations based on your Recipe Book`
                    "
                ></recommendation-carousel>

              
            <div
                class="home-header-title hero-carousel-header-title"
            >
                <h2>Featured Recipes</h2>
                <h3>
                    Recipe Reinvented: Where AI Meets Culinary Art
                </h3>
            </div>
                            
                            
              <div class="AIrecipe-list-container p-grid"   v-if="  aiRecipeList
                              ">


                <div class="AIcard-item p-col-12 p-md-3"
                     v-for="(aiRecipeList, index) in this.aiRecipeList"
                     :key="index"
                >
                  <aI-recipe-card
                      :recipe="aiRecipeList"
                      :title="aiRecipeList.title"
                      :complexity="aiRecipeList.complexity"
                      :tags="aiRecipeList.tags"
                      :image="aiRecipeList.recipedata.image"
                      :calories="aiRecipeList.resultCalories"
                      :carbs="aiRecipeList.resultCarbs"
                      :cooktime="aiRecipeList.recipedata.total_time"
                      :url="`https://cooking.freshy.com/recipe/${aiRecipeList.uuid}`"
                      :ratings="aiRecipeList.ai_score"
                  />
                </div>

              </div>
            </div>
        </div>
        <div id="home-footer">
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import queryString from "query-string";
import Button from "primevue/button";
import Skeleton from "primevue/skeleton";
import Carousel from "primevue/carousel";
import Footer from "../../components/footer/Footer.vue";
import Header from "../../components/header/Header.vue";
import RecipeCard from "../../components/recipeCards/RecipeCard";
import AIRecipeCard from "../../components/recipeCards/AIRecipeCard.vue";
import FeedCard from "../../components/recipeCards/FeedCard";
import RecommendationCarousel from "../../components/recommendationCarousel/RecommendationCarousel";
import RecipeCardPlaceholder from "../../components/recipeCards/RecipeCardPlaceholder.vue";

import FeaturedArticles from "../../components/FeaturedArticles.vue";
import LatestIdea from "../../components/LatestIdea.vue";
import IdeaList from "../../components/IdeaList.vue";
import IdeaListNewtab from "../../components/IdeaListNewtab.vue";
import userMixin from "../../store/mixins/user";
import extensionMixin from "../../components/componentMixins/extensionMixin";
import recipeMixin from "../../components/componentMixins/recipeMixin";
import newtabMixin from "../../components/componentMixins/newtabMixin";

import { getPopularRecipes, getTopRecipe, recipeSearch } from "../../fetchFunctions";
import {
    getMediaBreakpoint,
    insertInArrAtInterval,
    shuffleArr,
} from "../../utility";
import FirstRun from "../../components/firstrun/FirstRun";

export default {
    mixins: [userMixin, extensionMixin, recipeMixin, newtabMixin],
    components: {
        FirstRun,
        Header,
        Footer,
        Button,
        Skeleton,
        Carousel,
        RecipeCard,
        AIRecipeCard,
        FeedCard,
        RecommendationCarousel,
        RecipeCardPlaceholder,
        FeaturedArticles,
        LatestIdea,
        IdeaList,
        IdeaListNewtab,
    },
    beforeRouteLeave(to, from, next) {
        // under iframe for newtab links, set link+s target to _blank
        if (window.self !== window.top) {
            const url = new URL(window.self.location);
            url.pathname = to.path;
            if (to.query) {
                Object.keys(to.query).forEach(k => {
                    url.searchParams.set(k, to.query[k]);
                });
            }
            next(false);
            window.top.location = url.toString();
        } else next();
    },
    data() {
        const qs = queryString.parse(window.location.search);
        return {
            loadingCarousel: true,
            carouselData: null,
            recipeList: null,
            heroRecipe: null,
            showBigHeader: true,
            maxPerLine: 4,
            showFirstRun: qs.firstNT || false,
            primaryNewtabFeedData: null,
            primaryNewtabAdnetData: null,
            aiRecipeList: null,
        };
    },
    async mounted() {
        await this.init();
        this.maxPerLine = this.getPerLineMax();
        window.addEventListener("resize", () => {
            this.maxPerLine = this.getPerLineMax();
        });
        if (this.$route.params.sync) {
            if (await this.isExtensionUser()) {
                this.postMessageToExtension("login", {
                    access_token: localStorage.getItem("access_token"),
                });
                const extRecipes = await this.addExtensionMessageListener(
                    "syncRecipes"
                );
                this.onRecipeSync(extRecipes);
            }
        }
        const ga_bottom = document.querySelector("#ga_sticky_bottom");
        if (this.isNewtab && ga_bottom) {
            ga_bottom.style.display = "none";
        }
        // under iframe for newtab links, set link+s target to _blank
        if (window.self !== window.top) {
            document.querySelectorAll("a").forEach(el => {
                if (
                    el.getAttribute("href") &&
                    el.getAttribute("href") !== "#"
                ) {
                    el.setAttribute("target", "_blank");
                }
            });
        }



    },
    computed: {
        sizedRecipeList() {
            let list = this.sizedList(this.recipeList, this.maxPerLine);
            if (list && list.length > 10) list = list.slice(0, 10);
            return list;
        },
    },
    watch: {
        "$store.state.user.user.recipeList": {
            handler() {
                this.refreshRecipeList();
            },
            deep: true,
        },
    },
    methods: {
        getCarouselCalories(data) {
            if (data) {
                if (data.resultCalories) return parseInt(data.resultCalories);
                if (data.recipeData && data.recipeData.calories)
                    return parseInt(data.recipeData.calories);
            }
            return null;
        },
        getCarouselImage(data) {
            if (data) {
                if (data.recipeData && data.recipedata.image)
                    return data.recipedata.image;
                if (data.resultImage) return data.resultImage;
            }
            return null;
        },
        getCarouselTitle(data) {
            if (data) {
                if (data.recipeData && data.recipeData.title)
                    return data.recipedata.title;
                if (data.resultEntity) return data.resultEntity;
            }
            return null;
        },
        sizedList(prevList, maxPerLine) {
            if (prevList && prevList.length >= maxPerLine) {
                return prevList;
            }
            if (prevList && prevList.length > 0) {
                const list = [...prevList];
                for (let i = 0; i < maxPerLine - prevList.length; i++)
                    list.push({ isSubstitute: true });
                return list;
            }
            return null;
        },
        getPerLineMax() {
            const customBreakPoints = {
                xl: "1000px",
                lg: "800px",
                md: "600px",
                sm: "0px",
            };

            let perSet = 4;

            const size = getMediaBreakpoint(customBreakPoints);
            if (size) {
                if (size == "xl") perSet = 4;
                else if (size == "lg") perSet = 3;
                else if (size == "md") perSet = 2;
                else if (size == "sm") perSet = 1;
                else perSet = 5;
            }
            return perSet;
        },
        getCarouselVisible(list) {
            return Math.min(4, list.length);
        },
        getCarouselOptions(list) {
            return [
                {
                    breakpoint: "1200px",
                    numVisible: Math.min(4, list.length),
                    numScroll: 1,
                },
                {
                    breakpoint: "1000px",
                    numVisible: Math.min(3, list.length),
                    numScroll: 1,
                },
                {
                    breakpoint: "800px",
                    numVisible: Math.min(2, list.length),
                    numScroll: 1,
                },
                {
                    breakpoint: "600px",
                    numVisible: 1,
                    numScroll: 1,
                },
            ];
        },
        startLoading_All() {
            this.loadingCarousel = true;
        },
        stopLoading_All() {
            this.loadingCarousel = false;
        },
        async init() {
            // This should be at start of init function
            this.startLoading_All();

            const topRecipe = await getTopRecipe();
            if (
                topRecipe &&
                topRecipe.uuid &&
                topRecipe.data &&
                topRecipe.data.title &&
                topRecipe.data.image
            ) {
                topRecipe.data.uuid = topRecipe.uuid;
                this.heroRecipe = topRecipe.data;
            }

            // Get Recommendations
            const data = await getPopularRecipes();
            if (data && data.records && data.records.length > 0) {
                let carouselData = data.records;
                if (
                    this.$store.state.yfeeds.yFeed &&
                    this.$store.state.yfeeds.yFeed.data &&
                    this.$store.state.yfeeds.yFeed.data.data &&
                    this.$store.state.yfeeds.yFeed.data.data.length
                ) {
                    const feedItems = shuffleArr(
                        this.$store.state.yfeeds.yFeed.data.data
                    );
                    const firstItem = feedItems.shift();
                    carouselData = [
                        firstItem,
                        ...insertInArrAtInterval(data.records, feedItems),
                    ];
                }
                this.carouselData = carouselData;
            }

            //AI Recipe Load
          const res = await recipeSearch('*', '', {
            pageNo: 1,
            perPage: 100
          }, true);

          if (
              res &&
              res.total > 0 &&
              res.records &&
              res.records.length > 0
          ) {
            this.aiRecipeList = res.records;
            this.aiRecipeList.forEach(el => {
              el.url = `https://cooking.freshy.com/recipe/${el.uuid}`;
            });
          }

            //end of AI recipe load

          //begin of structured data

          // populating structured data of page
          const itemlList = {};
          // Adding static values
          itemlList["@context"] = "https://schema.org/";
          itemlList["@type"] = "ItemList";
          itemlList["name"] = "Carousel";
          itemlList.itemListElement = [];
          this.aiRecipeList.forEach((list, index) => {
            itemlList.itemListElement.push({
              "@type": "ListItem",
              position: index,
              "url": list.url,
              "name": list.title
            });

          });

          if ( itemlList) {
            const script = document.createElement("script");
            script.setAttribute("type", "application/ld+json");
            script.textContent = JSON.stringify(itemlList);
            document.head.appendChild(script);
          }
          //end of structured data
            // This should be at end of init function
            this.stopLoading_All();

            this.refreshRecipeList();

            window.addEventListener("scroll", () => {
                if (window.scrollY > 0) this.showBigHeader = false;
                else this.showBigHeader = true;
            });
        },
        getRandomFeedData() {
            if (
                this.$store.state.yfeeds.yFeed &&
                this.$store.state.yfeeds.yFeed.data &&
                this.$store.state.yfeeds.yFeed.data.data &&
                this.$store.state.yfeeds.yFeed.data.data.length
            ) {
                const feedItems = shuffleArr(
                    this.$store.state.yfeeds.yFeed.data.data
                );
                return feedItems[0];
            }
            return null;
        },
        refreshRecipeList() {
            if (
                this.loggedIn &&
                this.activeUser &&
                this.activeUser.recipeList
            ) {
                if (!Object.keys(this.activeUser.recipeList).length) {
                    this.recipeList = null;
                    return;
                }
                this.recipeList = this.formattedRecipeList(
                    this.activeUser.recipeList
                );
                this.recipeList.sort((a, b) => b.addDate - a.addDate);
            }
        },
        onRecipeClick(data) {
            this.$router.push({
                path: `/recipe/${data.uuid || data.data.uuid}`,
            });
        },
        onRecipeBookClick(data) {
            this.$router.push({
                name: "RecipeBook",
                params: {
                    uuid: data.uuid || data.data.uuid,
                },
            });
        },
    },
};
</script>

<style scoped src="./home.css"></style>
