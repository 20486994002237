<template>
    <div id="footer_root" class="p-d-print-none">
        <div
            id="home-footer"
            class="footer"
            :class="isNewtab ? 'white-bg' : ''"
        >
            <img
                v-if="!isNewtab"
                src="/assets/images/footerLogo.png"
                alt="Freshy Recipes"
            />
            <img
                v-else
                src="/assets/images/freshy_recipes_logo.svg"
                alt="Freshy Recipes"
            />
            <span :class="isNewtab ? 'newtab' : ''">
                All trademarks are property of their respective owners.
                Tightrope Interactive
            </span>
            <router-link :class="isNewtab ? 'newtab' : ''" :to="{ name: 'Tos' }"
                >Terms of Use</router-link
            >
            <router-link
                :class="isNewtab ? 'newtab' : ''"
                :to="{ name: 'PrivacyPolicy' }"
                >Privacy Policy</router-link
            >
            <router-link
                :class="isNewtab ? 'newtab' : ''"
                :to="{ name: 'PrivacyPolicy', hash: '#cali-consumer' }"
                >Do Not Sell My Personal Information</router-link
            >
            <router-link
                :class="isNewtab ? 'newtab' : ''"
                :to="{ name: 'Uninstall' }"
                >Uninstall</router-link
            >
            <router-link
                :class="isNewtab ? 'newtab' : ''"
                :to="{ name: 'ContactUs' }"
                >Contact</router-link
            >
        </div>
    </div>
</template>

<script>
import newtabMixin from "../componentMixins/newtabMixin";

export default {
    name: "Footer",
    mixins: [newtabMixin],
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style scoped src="./footer.css"></style>
