<template>
    <div id="overlay-auth-window-root">
        <Dialog
            id="search-auth-dialog"
            :modal="true"
            :dismissableMask="isEscapable"
            :showHeader="false"
            contentClass="auth-menu-auth-dialog-wrapper"
            :visible="showAuthWindow"
            @update:visible="hideDialog"
        >
            <div v-if="!hidePromo" id="left-auth-promo">
                <div id="auth-window-close-button" @click="hideDialog(false)">
                    <Button
                        id="awcb"
                        class="p-button-rounded"
                        icon="pi pi-times"
                    />
                    <span>Close</span>
                </div>
                <img
                    src="/assets/images/authPromo.jpg"
                    alt="Create an Account with Freshy Recipes"
                />
                <div
                    id="skip-with-extension"
                    v-if="extensionUser"
                    @click="$emit('sendToExtension')"
                >
                    <span>Click here</span> to continue to save &#38; print
                    recipes, and skip registration for now
                </div>
            </div>
            <AuthWindow
                @login="onLoginFunc"
                @logout="onLogoutFunc"
                :isResetPassword="isResetPassword"
                :isCreateAccount="isCreateAccount"
                :isReauthentication="isReauthentication"
                :code="code"
            />
        </Dialog>
    </div>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import AuthWindow from "./AuthWindow";
import extensionMixin from "../../componentMixins/extensionMixin";
import recipeMixin from "../../componentMixins/recipeMixin";

export default {
    props: ["mode", "escapable", "hidePromo"],
    mixins: [extensionMixin, recipeMixin],
    components: {
        Dialog,
        AuthWindow,
        Button,
    },
    data() {
        return {
            showAuthWindow: false,
            isEscapable: true,
            isResetPassword: null,
            isReauthentication: null,
            isCreateAccount: null,
            code: null,
            extensionUser: false,
        };
    },
    async mounted() {
        if (this.escapable === false) {
            this.isEscapable = false;
        }

        const params = new URLSearchParams(window.location.search);
        const code = params.get("oobCode");
        if (code) this.code = code;
        if (this.mode) {
            switch (this.mode) {
                case "reauthenticate":
                    this.isReauthentication = true;
                    break;
                case "resetPassword":
                    this.isResetPassword = true;
                    break;
                case "createAccount":
                    this.isCreateAccount = true;
            }
        }

        this.showAuthWindow = true;
        this.extensionUser = await this.isExtensionUser();
    },
    methods: {
        hideDialog(value) {
            if (!value) this.showAuthWindow = false;
            this.$emit("closed");
        },
        async onLoginFunc(val) {
            this.$emit("login", val);
            if (await this.isExtensionUser()) {
                this.postMessageToExtension("login", {
                    access_token: localStorage.getItem("access_token"),
                });
                const extRecipes = await this.addExtensionMessageListener(
                    "syncRecipes"
                );
                this.onRecipeSync(extRecipes);
            }
        },
        async onLogoutFunc(val) {
            this.$emit("logout", val);
            if (await this.isExtensionUser())
                this.postMessageToExtension("logout");
            localStorage.removeItem("access_token");
        },
    },
};
</script>

<style scoped src="./overlayAuthWindow.css"></style>
